import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "./grid";

LoadingCards.propTypes = {
    amount: PropTypes.number,
    col: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    Component: PropTypes.func,
};

export function LoadingCards({
    amount,
    col = 12,
    sm = 12,
    md = 3,
    lg = 3,
    xl = 2,
    Component,
}) {
    const cards = [];
    for (let i = 0; i < amount; i++) {
        cards.push(
            <Col key={i} col={col} sm={sm} md={md} lg={lg} xl={xl}>
                <Component />
            </Col>
        );
    }

    return <Row>{cards}</Row>;
}
