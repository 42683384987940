import React from "react";
import { Image } from "../ui";

export const LogoWithText = () => (
    <div className="logo-container">
        <Image alt="Spara Logo" src={"/spara-logo.svg"} />
    </div>
);

export const Logo = () => (
    <div className="logo-container">
        <Image alt="Spara Logo" src="/spara-logo.svg" />
    </div>
);
