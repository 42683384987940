import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

Col.propTypes = {
    // An array of React elements to display inside of the page.
    col: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    children: PropTypes.object,
    className: PropTypes.string,
};

export function Col({ col, sm, md, lg, xl, children, className, ...props }) {
    const classes = classNames(className, {
        [`col-sm-${sm}`]: sm,
        [`col-md-${md}`]: md,
        [`col-lg-${lg}`]: lg,
        [`col-xl-${xl}`]: xl,
        [`col-${col}`]: col,
    });

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
}
Container.propTypes = {
    // An array of React elements to display inside of the page.
    fluid: PropTypes.bool,
    className: PropTypes.string,
};
export function Container({ children, fluid = false, className, ...props }) {
    const classes = classNames(className, {
        "container-fluid": fluid,
        container: !fluid,
    });

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    );
}
Row.propTypes = {
    // An array of React elements to display inside of the page.
    children: PropTypes.array,
};
export function Row({ children }) {
    return <div className="row">{children}</div>;
}
