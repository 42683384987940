export default {
    welcome: "Byrjum að Spara",
    enable_offer: "Virkja tilboð",
    website: "Vefsíða",
    opening_hours: "Opnunartímar",
    offer_enabled_for: "Tilboð virkt í",
    sec: "sek.",
    close: "Loka",
    about: "Um Spara",
    most_popular: "Vinsælustu tilboðunum",
    newest_first: "Nýjustu tilboðum fyrst",
    oldest_first: "Elstu tilboðum fyrst",
    sort: "Raða eftir",
    active_now: "Virk núna",
    load_more: "Hlaða inn fleiri tilboðum...",
    use_this_code: "Notaðu þennan kóða til að virkja tilboðið",
    all_offers: "Öll tilboð",
    top_offers: "Helstu tilboð",
    home: "Heim",
    show_promocode: "Sýna afsláttarkóða",

    for_groups: "Fyrir hópa",
    for_brands: "Fyrir vörumerki",
    faq: "Algengar spurningar",
    instructions: "Leiðbeiningar",
    terms_and_privacy: "Skilmálar og persónuvernd",

    monday: "Mánudagur",
    tuesday: "Þriðjudagur",
    wednesday: "Miðvikudagur",
    thursday: "Fimmtudagur",
    friday: "Föstudagur",
    saturday: "Laugardagur",
    sunday: "Sunnudagur",

    monday_medium: "mán.",
    tuesday_medium: "þri.",
    wednesday_medium: "mið.",
    thursday_medium: "fim.",
    friday_medium: "fös.",
    saturday_medium: "lau.",
    sunday_medium: "sun.",

    monday_short: "M",
    tuesday_short: "Þ",
    wednesday_short: "M",
    thursday_short: "F",
    friday_short: "F",
    saturday_short: "L",
    sunday_short: "S",

    closed: "Lokað",

    offer_not_available_today: "Tilboð ekki virkt í dag",
    offer_not_available: "Tilboð ekki virkt",
    offer_was_available: "Tilboð var virkt",
    offer_today: "í dag",
    offer_is_available: "Tilboð er virkt",
    offer_is_available_all_day: "Tilboð er virkt í allan dag",
    offer_will_be_available: "Tilboð verður virkt",

    special_offer: "Sérkjör",
    two_for_one: "2 fyrir 1",
    introduction: "Kynning",

    login: "Innskráning",

    terms_conditions: "Notendaskilmálar",
    privacy_policy: "Persónuverndarskilmálar",
    what_is_torgid: "Hvað er Spara?",

    closed_groups: "Lokaðir hópar",
};
