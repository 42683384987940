export default {
    welcome: "Let's Save",
    enable_offer: "Enable Offer",
    website: "Website",
    opening_hours: "Opening Hours",
    offer_enabled_for: "Offer enabled for",
    sec: "sec.",
    close: "Close",
    about: "About Spara",
    most_popular: "Most Popular Offers",
    newest_first: "Newest Offers First",
    oldest_first: "Oldest Offers First",
    sort: "Sort by",
    active_now: "Active Now",
    load_more: "Load More Offers...",
    use_this_code: "Use this code to activate the offer",
    all_offers: "All Offers",
    top_offers: "Top Offers",
    home: "Home",
    show_promocode: "Show Promo Code",

    for_groups: "Fyrir hópa",
    for_brands: "Fyrir vörumerki",
    faq: "Algengar spurningar",
    instructions: "Leiðbeiningar",

    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",

    monday_medium: "Mon",
    tuesday_medium: "Tue",
    wednesday_medium: "Wed",
    thursday_medium: "Thu",
    friday_medium: "Fri",
    saturday_medium: "Sat",
    sunday_medium: "Sun",

    monday_short: "M",
    tuesday_short: "T",
    wednesday_short: "W",
    thursday_short: "T",
    friday_short: "F",
    saturday_short: "S",
    sunday_short: "S",

    closed: "Closed",

    offer_not_available_today: "Offer not available today",
    offer_not_available: "Offer not available",
    offer_was_available: "Offer was available",
    offer_today: "today",
    offer_is_available: "Offer is available",
    offer_is_available_all_day: "Offer is available all day today",
    offer_will_be_available: "Offer will be available",
    special_offer: "Special Offer",
    two_for_one: "2 for 1",
    introduction: "Introduction",

    login: "Login",

    terms_conditions: "User Terms and Conditions",
    privacy_policy: "Privacy Policy",

    what_is_torgid: "What is Spara?",

    closed_groups: "Closed Groups",
};
