import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

Image.propTypes = {
    src: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
};

function Image({ src, className, alt }) {
    return (
        <img alt={alt} src={src} className={classNames("img", "img-fluid", className)} />
    );
}

export default Image;
