import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { Image } from "../ui";

import CloseButton from "./close_button";

import { useRouter } from "next/router";

import PropTypes from "prop-types";
import GetTranslatedStrings from "../../helpers/GetTranslatedStrings";
import { FlexContainer } from "../ui/flex_container";
import UKFlag from "../icons/UKFlag";
import IcelandFlag from "../icons/IcelandFlag";

SideMenu.propTypes = {
    // Should the side menu be shown?
    show: PropTypes.bool,

    // Called when the side menu should close.
    onClose: PropTypes.func,
};

function SideMenu({ show, onClose }) {
    const t = GetTranslatedStrings();
    const router = useRouter();
    return (
        <aside
            style={{
                maxWidth: "600px",
                minWidth: "320px",
                right: show ? 0 : "-400px",
                zIndex: 3000,
                borderLeft: "1px solid #e6e6e6",
            }}
            className={classNames("side-menu", "torgid-background")}
        >
            <div
                style={{
                    padding: 30,
                }}
            >
                <CloseButton onClick={onClose} />

                <ul className="menu-items">
                    {/* <FlexContainer justifyContent="around" className="mb-3">
                        <Link href={router.asPath} locale="is">
                            <IcelandFlag
                                style={{
                                    height: 50,
                                    width: 50,
                                    border:
                                        router.locale == "is"
                                            ? "solid 3px " + "#DE2C48"
                                            : "solid 1px #ccc",
                                    borderRadius: 14,
                                }}
                            />
                        </Link>
                        <Link href={router.asPath} locale="en">
                            <UKFlag
                                style={{
                                    height: 50,
                                    width: 50,
                                    border:
                                        router.locale == "en"
                                            ? "solid 3px " + "#DE2C48"
                                            : "solid 1px #ccc",
                                    borderRadius: 14,
                                }}
                            />
                        </Link>
                    </FlexContainer> */}
                    <MenuItem href="/" text={t.home} />
                    <MenuItem href="https://admin.spara.is/" text={t.login} />
                    <MenuItem href="/hopar" text={t.for_groups} />
                    <MenuItem href="/vorumerki" text={t.for_brands} />
                    <MenuItem href="/hjalp" text={t.faq} />
                    {/* <MenuItem href="/leidbeiningar" text={t.instructions} /> */}
                </ul>
            </div>
        </aside>
    );
}

MenuItem.propTypes = {
    // The actual link.
    href: PropTypes.string,

    // The description for the link.
    text: PropTypes.string,
};

function MenuItem({ href, text }) {
    return (
        <li>
            <Link href={href}>{text}</Link>
        </li>
    );
}

MenuItemImage.propTypes = {
    // The actual link.
    href: PropTypes.string,

    // The description for the link.
    src: PropTypes.string,
};

function MenuItemImage({ href, src }) {
    return (
        <li>
            <a href={href}>
                <Image alt="App Store banner" className="app-store-image-img" src={src} />
            </a>
        </li>
    );
}

export default SideMenu;
