import React from "react";
import { Triangle } from "../shape/triangle";

import PropTypes from "prop-types";
import CloseIcon from "../icons/CloseIcon";

CloseButton.propTypes = {
    // When the user clicks the button.
    onClick: PropTypes.func,
};

export function CloseButton({ onClick }) {
    return (
        <>
            <button
                className={"close-button"}
                aria-label="Loka"
                style={{
                    border: "none",
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    padding: "10px",
                    backgroundColor: "#DE2C48",
                    borderRadius: "50%",
                }}
                onClick={() => onClick()}
            >
                <CloseIcon
                    style={{
                        color: "white",
                    }}
                />
            </button>
        </>
    );
}

export default CloseButton;
