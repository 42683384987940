import React from "react";
import { useState } from "react";
import Link from "next/link";
import classNames from "classnames";

import { Container, Title } from "../ui";
import Footer from "./footer";
import { LogoWithText } from "./logo";
import SideMenu from "./menu";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

Page.propTypes = {
    // The title of the page.
    title: PropTypes.string,

    // A custom header to use.
    Header: PropTypes.string,

    // The style of the header to display.
    headerStyle: PropTypes.string,

    // Class names to add to the page.
    className: PropTypes.string,
};

function Page({ children, title, Header, headerStyle, className }) {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <div className={classNames("page", className)}>
            <SideMenu
                show={showMenu}
                onClose={() => setShowMenu(false)}
                animation={classNames({ "slide-in-right": showMenu })}
            />

            <PageHeader
                Header={Header}
                headerStyle={headerStyle}
                onMenuClick={() => setShowMenu(true)}
            />

            <div className="page-content">
                <div className="page-title"></div>
                <div className="page-body">
                    <Title size={1} className="mb-4 fw-extra text-blue">
                        {title}
                    </Title>
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Page;

PageHeader.propTypes = {
    Header: PropTypes.string,
    headerStyle: PropTypes.string,
    onMenuClick: PropTypes.func,
};

function PageHeader({ Header, headerStyle, onMenuClick }) {
    const router = useRouter();
    const { locale } = router;

    const defaultHeaderStyle = {
        background: "#DE2C48",
        height: 70,
    };

    if (Header) {
        return <div className="page-header">{Header}</div>;
    }
    return (
        <div className="page-header" style={headerStyle ? headerStyle : defaultHeaderStyle}>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: 1296,
                }}
            >
                <Link href="/" locale={locale}>
                    <LogoWithText />
                </Link>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                    <MenuButton src="/menu.png" alt="Valmynd" onClick={onMenuClick} />
                    <a href="https://admin.spara.is/">
                        <MenuButton
                            src="/admin-user.png"
                            alt="Stjórnendaviðmót Spara"
                            onClick={() => {}}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

MenuButton.propTypes = {
    onClick: PropTypes.func,
};

function MenuButton({ onClick, alt, ...props }) {
    return (
        <button
            className="navbar-toggler"
            type="button"
            onClick={() => onClick()}
            style={{ float: "right", marginLeft: 20 }}
        >
            <img alt={alt} style={{ height: 25, objectFit: "contain" }} src={props.src} />
        </button>
    );
}
