import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

Modal.propTypes = {
    // An array of React elements to display inside of the page.
    children: PropTypes.array,
    // Class names to add to the Card.
    className: PropTypes.string,
};

function Modal({ children, className }) {
    return (
        <>
            <div
                className={classNames("modal show", className)}
                style={{
                    display: "block",
                }}
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">{children}</div>
                </div>
            </div>
            <ModalBackdrop />
        </>
    );
}

ModalBody.propTypes = {
    // An array of React elements to display inside of the page.
    children: PropTypes.array,
};

export function ModalBody({ children }) {
    return (
        <div
            className="modal-body"
            style={{
                marginRight: "15px",
                marginLeft: "15px",
            }}
        >
            {children}
        </div>
    );
}

ModalFooter.propTypes = {
    // An array of React elements to display inside of the page.
    children: PropTypes.array,
};

export function ModalFooter({ children }) {
    return <div className="modal-footer">{children}</div>;
}

CloseButton.propTypes = {
    // An array of React elements to display inside of the page.
    onClick: PropTypes.func,
};

export function CloseButton({ onClick }) {
    return (
        <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onClick()}
        >
            <span aria-hidden="true">&times;</span>
        </button>
    );
}
ModalBackdrop.propTypes = {
    // An array of React elements to display inside of the page.
    onClick: PropTypes.func,
};

export function ModalBackdrop() {
    return <div className="modal-backdrop fade show"></div>;
}

export default Modal;
