import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const iconSize = {
    small: "sm",
    large: "lg",
};

Icon.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
};
function Icon({ icon, className, size, ...props }) {
    return (
        <i className={classNames("icon", className, size)}>
            <FontAwesomeIcon icon={icon} size={size} {...props} />
        </i>
    );
}

export default Icon;
