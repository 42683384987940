import React from "react";
import classNames from "classnames";

export const FlexContainer = (props) => {
    const { children, className, justifyContent, alignItems, column } = props;
    return (
        <div
            {...props}
            className={classNames(
                "d-flex",
                {
                    [`justify-content-${justifyContent}`]: justifyContent,
                    [`align-items-${alignItems}`]: alignItems,
                    "flex-column": column,
                },
                className
            )}
        >
            {children}
        </div>
    );
};
