import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export const buttonSize = {
    small: "sm",
    large: "lg",
};

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.string,
    isBlock: PropTypes.bool,
    isDisabled: PropTypes.bool,
    size: PropTypes.string,
};

export function Button({
    text,
    onClick,
    type = "primary",
    isBlock,
    /* isDisabled, */
    size,
    ...props
}) {
    return (
        <button
            {...props}
            //disabled={isDisabled}
            onClick={() => onClick()}
            className={classNames("btn", {
                [`btn-${type}`]: type,
                [`btn-${size}`]: size,
                "btn-block": isBlock,
            })}
        >
            {text}
        </button>
    );
}
