import * as React from "react";

function UKFlag(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            {...props}
        >
            <path
                d="M400 512H112C50.144 512 0 461.856 0 400V112C0 50.144 50.144 0 112 0h288c61.856 0 112 50.144 112 112v288c0 61.856-50.144 112-112 112z"
                fill="#41479b"
            />
            <g fill="#f5f5f5">
                <path d="M512 399.989v-24.587l-79.419-52.033H512v-26.948H296.421V512h26.947V348.46L494.3 460.45a111.414 111.414 0 0013.75-30.896M7.531 440.443a111.728 111.728 0 0010.168 20.007l170.932-111.99V512h26.947V296.421H0v26.948h79.419L0 375.402V400c0 4.306.252 8.553.725 12.733M0 115.807v20.791l79.419 52.034H0v26.947h215.579V0h-26.947v163.54L17.699 51.55A111.378 111.378 0 003.114 85.7M504.321 71.169a111.622 111.622 0 00-10.021-19.62L323.368 163.54V0h-26.947v215.579H512v-26.947h-79.419L512 136.598V112c0-4.46-.269-8.858-.776-13.182" />
            </g>
            <g fill="#ff4b55">
                <path d="M296.421 0h-80.842v215.579H0v80.842h215.579V512h80.842V296.421H512v-80.842H296.421z" />
                <path d="M138.395 323.369L.725 412.733a111.28 111.28 0 006.807 27.71l180.359-117.075h-49.496v.001zM344.465 323.369l163.586 106.186A112.142 112.142 0 00512 400v-.011l-118.039-76.621-49.496.001zM161.684 188.632L3.114 85.7A112.234 112.234 0 000 112v3.807l112.188 72.825h49.496zM372.863 188.632l138.36-89.814a111.284 111.284 0 00-6.902-27.648L323.368 188.632h49.495z" />
            </g>
        </svg>
    );
}

export default UKFlag;
