import is from "../../public/locales/is/translation";
import en from "../../public/locales/en/translation";
import { useRouter } from "next/router";

function GetTranslatedStrings() {
    const locale = useRouter()?.locale;
    switch (locale) {
        case "is":
            return is;
        case "en":
            return en;
        default:
            return is;
    }
}

export default GetTranslatedStrings;
