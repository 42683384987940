import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Container, Image, Title } from "../ui";
import { Logo } from "./logo";

import GetTranslatedStrings from "../../helpers/GetTranslatedStrings";
import SparaLogo from "../SparaLogo";

function Footer() {
    return (
        <footer className="footer">
            <Container>
                <div className="logo-container">
                    <Link href="/">
                        <SparaLogo style={{ color: "#DE2C48" }} />
                    </Link>
                </div>
                <div className="footer-content">
                    <Navigation />
                </div>
            </Container>
        </footer>
    );
}

function Navigation() {
    const t = GetTranslatedStrings();
    return (
        <div className="footer-navigation">
            <ul>
                <LinkListItem href="/skilmalar" text={t.terms_and_privacy} />
            </ul>
            {/* <div style={{ width: 20, marginLeft: 10 }} className="float-end">
                <Link
                    href="https://www.facebook.com/1819Torgid/"
                    prefetch={false}
                >
                    <a target={"_blank"} aria-label="Facebook">
                        <Image alt="Facebook" src="/facebook.png" />
                    </a>
                </Link>
            </div> */}
            <div style={{ width: 125, marginLeft: 10 }} className="float-end">
                <Link
                    href="https://play.google.com/store/apps/details?id=com.torgid"
                    prefetch={false}
                    target={"_blank"}
                    aria-label="Google Play"
                >
                    <Image alt="Google Play" src="/google_play.png" />
                </Link>
            </div>
            <div style={{ width: 125 }} className="float-end">
                <Link
                    href="https://apps.apple.com/is/app/1819-torgið/id1559929453"
                    prefetch={false}
                    target={"_blank"}
                    aria-label="App Store"
                >
                    <Image alt="App Store" src="/apple_app_store.png" />
                </Link>
            </div>
        </div>
    );
}

LinkListItem.propTypes = {
    // The actual link.
    href: PropTypes.string,

    // The description for the link.
    text: PropTypes.string,
};

function LinkListItem({ href, text }) {
    return (
        <li>
            <Link href={href} style={{ textDecoration: "none" }}>
                {text}
            </Link>
        </li>
    );
}
export default Footer;
