import React from "react";
import classNames from "classnames";

export function Title({ size = 3, className, children, isSubtitle, ...props }) {
    const classes = classNames("title", className, {
        [`is-${size}`]: size,
        "is-subtitle": isSubtitle,
    });

    switch (size) {
        case 1:
            return (
                <h1 {...props} className={classes}>
                    {children}
                </h1>
            );
        case 2:
            return (
                <h2 {...props} className={classes}>
                    {children}
                </h2>
            );
        case 3:
            return (
                <h3 {...props} className={classes}>
                    {children}
                </h3>
            );
        case 4:
            return (
                <h4 {...props} className={classes}>
                    {children}
                </h4>
            );
        case 5:
            return (
                <h5 {...props} className={classes}>
                    {children}
                </h5>
            );
        case 6:
            return (
                <h6 {...props} className={classes}>
                    {children}
                </h6>
            );
    }
}

export function Subtitle({ size = 3, className, children }) {
    const classes = classNames("subtitle", className, {
        [`is-${size}`]: size,
    });

    switch (size) {
        case 1:
            return <h1 className={classes}>{children}</h1>;
        case 2:
            return <h2 className={classes}>{children}</h2>;
        case 3:
            return <h3 className={classes}>{children}</h3>;
        case 4:
            return <h4 className={classes}>{children}</h4>;
        case 5:
            return <h5 className={classes}>{children}</h5>;
        case 6:
            return <h6 className={classes}>{children}</h6>;
    }
}
